import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from "@components/SEO"
import { Grid } from "semantic-ui-react"
import { CombinationFinder } from "@components/CombinationFinder"
import { H2 } from "@styles/Global.styles"
import { mapToPlateFormatList } from "@mappers/index"
import {
  getVehicleTypeByQueryString,
  getCombinationByQueryString,
  getTransactionTypeByQueryString,
  getIsGiftingByQueryString,
  isMobile,
  getCleanCampaignByQueryString,
  getDesignIdByQueryString,
} from "@utils/Helpers"
import {
  VehicleType,
  CurrentPlateDesign,
  VehicleTypesSetting,
  TransactionType,
  TransactionTypesSetting,
} from "@models/ProductModels"

import { DefaultCombination } from "@utils/Constant"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { mapToCheckoutStep } from "@utils/mapper"
import { HeaderStep } from "@components/SelectStyle/SelectStyle.styles"
import { mapToSweepstakeCampaignList } from "@mappers/PlateFormat/mapper"
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from "@redux/localStorage"
import { version } from "os"
import { BreadCrumb } from "@components/CombinationFinder/CombinationFinder.styles"
var get = require("lodash/get")

type CreateNewStates = {
  vehicleType: VehicleType
  transactionType: TransactionType
  combination: string
  plateDesign?: CurrentPlateDesign
  categoryName?: string
  isGift?: boolean
  cleanCampaign: boolean
  designId: number
}

const queryString = require("query-string")

const loadFromQuerystring = (location: any): CreateNewStates => {
  if (location.search) {
    const query = queryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true,
    })
    const vehicleType = getVehicleTypeByQueryString(query.vt)
    const combination = getCombinationByQueryString(query.combination)
    const transactionType = getTransactionTypeByQueryString(query.tt)
    const isGift = getIsGiftingByQueryString(query.gift)
    const cleanCampaign = getCleanCampaignByQueryString(query.clean)
    const designId = getDesignIdByQueryString(query.designid)
    return {
      vehicleType: vehicleType,
      transactionType: transactionType,
      combination: combination,
      isGift: isGift,
      cleanCampaign: cleanCampaign,
      designId: designId
    }
  }
  return {
    vehicleType: VehicleTypesSetting.Car,
    transactionType: TransactionTypesSetting.New,
    combination: "",
    isGift: false,
    cleanCampaign: false,
    designId: 0
  }
}

const loadCombinationFromState = (
  combination: string,
  vehicleType: VehicleType
) => {
  if (combination === get(DefaultCombination, vehicleType.name)) {
    return ""
  }
  return combination
}

const loadFromStates = (states: any): CreateNewStates | undefined => {
  if (states && states.vehicleType && states.transactionType) {
    return {
      vehicleType: states.vehicleType,
      transactionType: states.transactionType,
      combination: loadCombinationFromState(
        states.combination,
        states.vehicleType
      ),
      plateDesign: states.plateDesign,
      categoryName: states.categoryName,
      isGift: states.isGift,
      cleanCampaign: states.cleanCampaign,
      designId: states.designId
    }
  }
  return undefined
}
const CreatePage = (props: any) => {

  if(isMobile() === undefined){
    return null;
  }
  
  const { elements } = props.data.kontentItemAbLandingPage.elements.create_plate.linked_items[0];
  const { location } = props
  const step = mapToCheckoutStep(elements)
  const apiUrl = props.data.site.siteMetadata.apiUrl
  const seo = mapToSEO(elements)
  const plateFormats = mapToPlateFormatList(elements.plate_formats)
  const sweepstakeCampaign = mapToSweepstakeCampaignList(elements.campaign_items)[0]
  const heading = elements.heading.value;
  //data passed through query string can only be vehicle type, combination, transaction type

  const states = loadFromStates(location.state)
  const queryData =
    states === undefined ? loadFromQuerystring(location) : states
  const reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey;
  const reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2;
  const emailValidationToken = props.data.site.siteMetadata.emailValidationToken;
  const alternativeMessage = elements.alternative_message.value;
  if (queryData.cleanCampaign){
    const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
    if(campaignInLocal) {
      localStorage.removeItem(LOCALSTORAGE_CAMPAIGN_CURRENT); 
    }     
  }

  return (
    <Layout version="simple" currentStep={step} location={props.location}>
      <SEO {...seo} />

      <GenericBannerContainer
        padding={{
          mobile: {
            top: 30,
            bottom: 190,
          },
          desktop: {
            top: 50,
            bottom: 80,
          },
        }}
        backgroundColor="black"
        paddingTop={50}
        paddingBottom={80}
      >
        <Grid>
          <Grid.Row>
            {isMobile() ? <Grid.Column width={16} textAlign="right"><HeaderStep>Step 1/3</HeaderStep></Grid.Column> : <Grid.Column width={16} textAlign="right"><BreadCrumb><span>Plate Search</span><svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
  <path d="M6.70719 5.79402C7.09767 6.1845 7.09767 6.81863 6.70719 7.2091L1.70915 12.2071C1.31867 12.5976 0.684547 12.5976 0.294075 12.2071C-0.0963974 11.8167 -0.0963974 11.1825 0.294075 10.7921L4.58615 6.5L0.297199 2.20793C-0.0932737 1.81745 -0.0932737 1.18333 0.297199 0.792854C0.687671 0.402382 1.3218 0.402382 1.71227 0.792854L6.71032 5.7909L6.70719 5.79402Z" fill="white"/>
</svg>Select Design</BreadCrumb></Grid.Column>}
            <Grid.Column width={16} textAlign="left">
              {isMobile() ? <H2 color="white">{heading}</H2> : <H2 color="white">{heading}</H2>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <CombinationFinder
                vehicleType={queryData.vehicleType}
                transactionType={queryData.transactionType}
                combination={queryData.combination}
                isGift={queryData.isGift}
                plateDesign={queryData.plateDesign}
                plateformats={plateFormats}
                categoryName={queryData.categoryName}
                apiUrl={apiUrl}
                title=""
                alternativeMessage={alternativeMessage}
                reCaptchaSiteKey={reCaptchaSiteKey}
                reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}  
                campaign={sweepstakeCampaign}     
                emailValidationToken={emailValidationToken}
                designId={queryData.designId}
                darkTheme={true}         
              ></CombinationFinder>
            </Grid.Column>
            <Grid.Column width={6}></Grid.Column>
          </Grid.Row>
        </Grid>
      </GenericBannerContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
        emailValidationToken
        reCaptchaSiteKey
        reCaptchaSiteKeyV2
      }
    }
    kontentItemAbLandingPage {
      elements {
        create_plate {
          linked_items {
            ... on
            KontentItemCreateNewPlate {
              elements {
                seo__noindex {
                  value {
                    name
                    codename
                  }
                }
                seo__nofollow {
                  value {
                    name
                    codename
                  }
                }
                page_info___checkout__progress {
                  value {
                    codename
                    name
                  }
                }
                seo__page_title {
                  value
                }
                seo__page_description {
                  value
                }
                page_info___checkout__title {
                  value
                }
                heading {
                  value
                }
                url {
                  value
                }
                alternative_message{
                  value
                }
                plate_formats {
                  linked_items {
                    ... on KontentItemProductCategoryFormats {
                      id
                      elements {
                        description {
                          value
                        }
                        format_type {
                          value {
                            codename
                            name
                          }
                        }
                        base_price {
                          value
                        }
                        example_image {
                          value {
                            url
                            description
                          }
                        }
                        title {
                          value
                        }
                      }
                    }
                  }
                }
                campaign_items {
                  linked_items {
                    ... on KontentItemSweepstakeCampaign {
                      elements {
                        campaign_name {
                          value
                        }
                        expire_on {
                          name
                          type
                          value
                        }
                        valid_from {
                          name
                          type
                          value
                        }                        
                        transaction_type {
                          value {
                            name
                            codename
                          }
                        }                        
                        ads {
                          linked_items {
                            ... on KontentItemCampaignAds {
                              elements {
                                left_title_1 {
                                  value
                                }
                                left_title_2 {
                                  value
                                }
                                left_description {
                                  value
                                }
                                left_picture {
                                  value {
                                    name
                                    description
                                    url
                                    width
                                    height
                                  }
                                }
                                right_title {
                                  value
                                }
                                right_description_1 {
                                  value
                                }
                                right_description_2 {
                                  value
                                }
                                right_description_3 {
                                  value
                                }
                                left_background_colors {
                                  value {
                                    name
                                    codename
                                  }
                                }
                                right_background_colors {
                                  value {
                                    name
                                    codename
                                  }
                                }
                              }
                            }
                          }
                        }
                        popup {
                          linked_items {
                            ... on KontentItemCampaignPopup {
                              elements {
                                header {
                                  value
                                }
                                header_campaign_name{
                                  value
                                }
                                picture {
                                  value {
                                    name
                                    description
                                    url
                                    width
                                    height
                                  }
                                }                              
                                form_title {
                                  value
                                }
                                email_opt_in_text{
                                  value
                                }
                                footer {
                                  value
                                }
                                terms___conditions{
                                  value
                                }
                                background_color {
                                  value {
                                    name
                                    codename
                                  }
                                }
                                response {
                                  value
                                }
                                input_field_background_color {
                                  value {
                                    name
                                    codename
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


export default CreatePage